<template>
  <div
    v-if="props.client && (showRequestsTodayCount || isFirstRequestInClientHistory)"
  >
    <div v-if="showRequestsTodayCount">
      <el-tooltip
        placement="top"
      >
        <div class="tw-flex tw-items-center">
          <icon-warning-triangle
            :class="requestsTodayCount >= 10 ? 'tw-text-danger-500' : 'tw-text-secondary-500'"
          />
        </div>
        <template #content>
          <template v-if="props.request">
            Dnes již <strong>{{ requestsTodayCount }}</strong>. požadavek
          </template>
          <template v-else>
            Dnes již <strong>{{ requestsTodayCount }}</strong> {{ pluralize(requestsTodayCount, 'vyřízený požadavek', 'vyřízené požadavky', 'vyřízených požadavků') }}
          </template>
        </template>
      </el-tooltip>
    </div>

    <div v-if="isFirstRequestInClientHistory">
      <el-tooltip
        placement="top"
      >
        <div class="tw-flex tw-items-center">
          <icon-warning-triangle
            class="tw-text-secondary-500"
          />
        </div>
        <template #content>
          První požadavek {{ props.client?.type === 2 ? 'organizace' : 'klienta' }}
        </template>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  client: {
    type: Object,
    required: true,
  },
  request: {
    type: Object,
    default: null,
  },
});

// Computed
const isRequestInProgress = computed(() => props.request && props.request.state_id === 2);
const todayRequestNumber = computed(() => props.request ? props.request.today_request_number : 0);
const requestsTodayCount = computed(() => {
  if (props.request) {
    return todayRequestNumber.value;
  }
  return props.client.requests_today_count;
});
const showRequestsTodayCount = computed(() => requestsTodayCount.value >= 7);
const isFirstRequestInClientHistory = computed(() => (props.client.requests_history_count - (isRequestInProgress.value ? 1 : 0)) === 0 && useAuthStore().user?.is_operator);
</script>
